@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Roboto", sans-serif;
  }
}

.bg-pic {
  background-image: url(./image/Star.svg);
  background-repeat: repeat;
  background-size: 220px;
  background-position: 50%;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1;
}

.bg-gradient {
  background-image: linear-gradient(
    to right,
    rgb(243, 63, 114),
    rgb(255, 113, 107)
  );
}

.bg-gradient:hover {
  background-image: linear-gradient(
    to right,
    rgb(245, 37, 96),
    rgb(255, 74, 67)
  );
}

.bg-white {
  box-shadow: 0px 0px 0px 2px white inset;
}

.text-gradient {
  background: -webkit-linear-gradient(
    left,
    rgb(243, 63, 114),
    rgb(255, 113, 107)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.full-bleed-violet {
  box-shadow: 0 0 0 100vmax #cbc8df;
  clip-path: inset(0 -100vmax);
}

.full-bleed-white {
  box-shadow: 0 0 0 100vmax #f1f5f9;
  clip-path: inset(0 -100vmax);
}

.pic {
  border: solid #1a0f31 2px;
}

.bg-blur {
  box-shadow: 0 -30px 15px 10px #1a0f31;
}
